<template>
  <div>
    <MatchStatKillBars v-if="matchData.info.teams" :teams="matchData.info.teams" />

    <div v-if="false">
      <div v-for="(team, team_id) in matchData.info.teams" :key="team_id" class="mt-5 mb-5">
        <b-table borderless hover :fields="fields" :items="team.players">
          <template #cell(player)="data">
            <div class="d-flex align-items-center">
              <router-link :to="`/team/${team.id}`">
                <TeamLogo :src="team.icon_url" :team_name="team.name" />
              </router-link>
              <div class="ml-2">
                <h2>
                  <router-link v-if="data.item.id" :to="`/player/${data.item.id}/stats/matchData.info.map`">
                    {{ data.item.game_name }}
                  </router-link>
                  <span v-else>
                    {{ data.item.game_name }}
                  </span>
                </h2>
                <router-link :to="`/team/${team.id}`" class="text-muted font-weight-lighter">
                  {{ team.name }}
                </router-link>
              </div>
            </div>
          </template>

          <template #cell(agent)="data">
            <img :src="`/images/agents/${data.value}.png`" class="img-fluid" style="max-height: 40px" alt="" />
          </template>

          <template #cell()="data">
            <div
              :class="[
                'info-cell',
                'd-flex',
                'justify-content-center',
                'align-items-center',
                `info-cell--${!data.value ? 'empty' : 'full'}`,
              ]"
            >
              {{ data.value ? data.value : '·' }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <MatchStatKillMatrix :match-data="matchData.info" />
  </div>
</template>

<script>
import MatchStatKillBars from '@/components/match/stats/KillBars.vue'
import MatchStatKillMatrix from '@/components/match/stats/KillMatrix.vue'
import TeamLogo from '@/components/UI/TeamLogo.vue'

export default {
  name: 'GridMatchPerformance',
  components: {
    MatchStatKillBars,
    MatchStatKillMatrix,
    TeamLogo,
  },
  inject: ['matchData'],
  data() {
    return {
      fields: [
        {
          key: 'player',
          label: '',
          headerTitle: 'Team Player',
        },
        {
          key: 'agent',
          label: '',
        },
        {
          key: 'double_kills',
          label: '2K',
        },
        {
          key: 'triple_kills',
          label: '3K',
        },
        {
          key: 'quadra_kills',
          label: '4K',
        },
        {
          key: 'penta_kills',
          label: '5K',
        },
        {
          key: 'one_v_one',
          label: '1V1',
        },
        {
          key: 'one_v_two',
          label: '1V2',
        },
        {
          key: 'one_v_three',
          label: '1V3',
        },
        {
          key: 'one_v_four',
          label: '1V4',
        },
        {
          key: 'one_v_five',
          label: '1V5',
        },
        {
          key: 'econ_rating',
          label: 'ECON',
          formatter: value => Math.round(value), // TODO refactor formatters
        },
        {
          key: 'spikes_planted',
          label: 'PL',
        },
        {
          key: 'spikes_defused',
          label: 'DE',
        },
      ],
    }
  },

  computed: {},
}
</script>

<style lang="scss" scoped>
.info-cell {
  width: 40px;
  height: 40px;

  &--empty {
    background: $gray-600;
  }

  &--full {
    background: $info;
    color: $secondary;
    font-family: $font-josefin;
    font-size: 1.25rem;
  }
}

.team-logo {
  max-width: 50px;
  max-height: 60px;
}

h2 {
  margin: 0;
  font-size: 1.25rem;
}
</style>
