<template>
  <div>
    <div class="my-5">
      <MatchChartEconomy :economy="teamsEconomy" :teams="teams" />
    </div>

    <div class="mt-5 mb-5">
      <AppTable :fields="items_preview_fields" :items="matchData.info.economy.economyPreview">
        <template #cell(team_name)="data">
          <div class="d-flex align-items-center">
            <router-link :to="`/team/${data.item.id}`" class="h5 mt-1 ml-2">
              <TeamLogo :src="data.item.icon_url" :team_name="data.value" />
              {{ data.value }}
            </router-link>
          </div>
        </template>

        <template #cell(eco)="data">
          {{ data.item.eco.played }}
          ({{ data.item.eco.won }})
        </template>

        <template #cell(semi_eco)="data">
          {{ data.item.semi_eco.played }}
          ({{ data.item.semi_eco.won }})
        </template>

        <template #cell(semi_buy)="data">
          {{ data.item.semi_buy.played }}
          ({{ data.item.semi_buy.won }})
        </template>

        <template #cell(full_buy)="data">
          {{ data.item.full_buy.played }}
          ({{ data.item.full_buy.won }})
        </template>
      </AppTable>
    </div>

    <div class="mt-5 mb-5 text-center eco-legend">
      <span class="mr-5"><strong>P</strong> Pistol round</span>
      <span class="mr-5"><strong>$</strong> Eco: 0-5k</span>
      <span class="mr-5"><strong>$$</strong> Semi-eco: 5-10k</span>
      <span class="mr-5"><strong>$$$</strong> Semi-buy: 10-20k</span>
      <span><strong>$$$$</strong> Full buy: 20k+</span>
    </div>

    <div class="pb-5 mb-5">
      <EconomyTableRow
        v-for="(team, i) in matchData.info.economy.economyFirstHalf"
        :key="team.id"
        :position="i === 0 ? 'first' : 'second'"
        :team_id="team.id"
        :team_name="team.name"
        :icon_url="team.icon_url"
        :role="team.role"
        :rounds="team.rounds"
      />
    </div>

    <div class="pb-5 mb-5">
      <EconomyTableRow
        v-for="(team, i) in matchData.info.economy.economySecondHalf"
        :key="team.id"
        :position="i === 0 ? 'first' : 'second'"
        :team_id="team.id"
        :team_name="team.name"
        :icon_url="team.icon_url"
        :role="team.role"
        :rounds="team.rounds"
      />
    </div>
  </div>
</template>

<script>
import AppTable from '@/components/generic/Table.vue'
import EconomyTableRow from '@/components/Match.old/EconomyTableRow.vue'
import MatchChartEconomy from '@/components/match/charts/Economy.vue'
import TeamLogo from '@/components/UI/TeamLogo.vue'

export default {
  name: 'GridMatchEconomy',
  components: {
    MatchChartEconomy,
    EconomyTableRow,
    TeamLogo,
    AppTable,
  },
  inject: ['matchData'],
  data() {
    return {
      items_preview_fields: [
        {
          key: 'team_name',
          label: '',
        },
        {
          key: 'pistol_won',
          label: 'P (won)',
          class: 'text-right',
        },
        {
          key: 'eco',
          label: '$ (won)',
          class: 'text-right',
        },
        {
          key: 'semi_eco',
          label: '$$ (won)',
          class: 'text-right',
        },
        {
          key: 'semi_buy',
          label: '$$$ (won)',
          class: 'text-right',
        },
        {
          key: 'full_buy',
          label: '$$$$ (won)',
          class: 'text-right',
        },
      ],
    }
  },

  computed: {
    teamsEconomy() {
      return [
        [].concat(
          this.matchData.info.economy.economyFirstHalf?.[0].rounds,
          this.matchData.info.economy.economySecondHalf?.[0].rounds
        ),
        [].concat(
          this.matchData.info.economy.economyFirstHalf?.[1].rounds,
          this.matchData.info.economy.economySecondHalf?.[1].rounds
        ),
      ]
    },

    teams() {
      return this.matchData.info.economy.economyFirstHalf
    },
  },
}
</script>

<style lang="scss" scoped>
.eco-legend {
  border-top: 1px solid $gray-800;
  border-bottom: 1px solid $gray-800;
  padding: 0.5rem;

  @extend %font-mono-sm;
}

.team-logo {
  max-width: 30px;
  max-height: 30px;
}

h2.small {
  margin: 0;
  font-size: 1.25rem;
}
</style>
